
import SeoTag from './SeoContainer.vue';
import Panel from './notification/Panel.vue';
import TabContainer from './tab/TabContainer.vue';
import SearchBar from './searchBar/SearchBar.vue';
import { mapMutations } from 'vuex';
import axios from "axios";
import AppDirectIcon from './AppDirectIcon.vue';
import debounce from "lodash.debounce";
export default {
    name: "header-component",
    head() {
        return {
            script: [
                {
                    src: "https://member.pixnet.cc/api/checklogin.php?js=1&unique=pix&timestamp=" + Date.now().toString().substring(0, Date.now().toString().length - 3) + "&type=3"
                }
            ],
        }
    },
    data() {
        return {
            input_value: "",
            show_search_container: false,
            open_panel_type: '',
            has_unread: false,
            isHasAutoComplete: false
        }
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_data() {
            return this.$store.state.login.member_data;
        },
        member_thumb() {
            return this.$store.state.login.member_data.member_thumb;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        search_active_status() {
            return this.$store.state.search.search_active;
        },
        window_width() {
            return window.innerWidth;
        },
        tip() {
            return this.$store.state.app_direct.tip;
        },
        dark_mode() {
            if (this.$route.name === "Channel-Movie" || this.$route.name === "Channel-Adult") {
                return true;
            } else {
                return false;
            }
        },
        router_name() {
            return this.$route.name;
        },
        search_history() {
            return this.$store.state.search.search_history;
        },
    },
    components: { SeoTag, Panel, TabContainer, SearchBar, AppDirectIcon },
    methods: {
        ...mapMutations('tab', [
            'updateTabId',
            'updateTabType',
            'updateTabLeft',
            'updateTabWidth',
            'updateRouteName'
        ]),
        ...mapMutations('banner', [
            'cleanBannerData',
        ]),
        ...mapMutations('search', [
            'updateSearchHistory',
            'updateSearchActiveStatus',
            'updateSearchAutocomplete',
            'updateInputValueActiveStatus'
        ]),
        ...mapMutations('login', [
            'setPixMemberToken',
            'setSafariPixMemberToken',
            'setLogoutData',
            'setMemberData'
        ]),
        ...mapMutations('app_direct', [
            'setTipStatus',
        ]),
        ...mapMutations('adult', [
            'changeIs18UpStatus'
        ]),
        handleBell() {
            // 未登入時鈴鐺導去登入頁，登入後開啟通知列表
            if (!this.is_login) {
                this.goLogin();
            } else {
                this.openPanel('notify');
            }
        },
        handlePanel(type) {
            if (!this.is_login) {
                this.goLogin();
                return;
            }
            this.openPanel(type);
            // 點擊選單以外區域則關閉選單
            let vm = this;
            document.body.addEventListener('click', function eventHandler(e) {
                // e.stopPropagation();
                if (e.target.closest('.panel-slidebox')) {
                    return;
                }
                vm.open_panel_type = '';
                document.body.removeEventListener('click', eventHandler);
            });
        },
        openPanel(type) {
            if (this.window_width <= 1024) {
                document.body.classList.add('body-lock')
            }
            this.open_panel_type = this.open_panel_type === type ? '' : type;
        },
        closePanel() {
            document.body.classList.remove('body-lock')
            this.open_panel_type = '';
        },
        switchPanel() {
            if (this.open_panel_type === 'notify') {
                this.open_panel_type = 'login';
            }
        },
        paInputValue(text) {
            let value = '全站搜尋::' + text
            _piq.push(['trackEvent', 'rs2022::十大頻道::搜尋', 'search::全站搜尋::button::user_action', value, '']);
        },
        inputValue(keyword) {
            const vm = this;
            if (keyword != '') {
                if(vm.isHasAutoComplete) {
                    vm.paInputValue(keyword);
                    window.open(`https://curation.pixnet.net/${keyword}`, '_blank');
                    vm.addToLocalStorage(keyword);
                    vm.setSearchTextUrl(keyword);
                    vm.input_value = '';
                }
            }
        },
        addToLocalStorage(keyword) {
            let searchObj = {
                name: keyword,
                link: `https://curation.pixnet.net/${keyword}`
            }

            localStorage.setItem("pix_search_history", JSON.stringify([...this.search_history, searchObj]));
            // this.updateSearchHistory([...this.search_history, searchObj]);
        },
        getSearchHistory() {
            let history = JSON.parse(localStorage.getItem("pix_search_history"));
            history = Array.isArray(history) ? history : [];
            this.updateSearchHistory(history);
        },
        goLogin() {
            const url = window.location.host;
            location.href = "https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2F" + url + "%3Fversions%3D2023";
        },
        goLogout() {
            const url = window.location.host;
            location.href = "https://member.pixnet.cc/logout?done=https%3A%2F%2F" + url + "%3Fversions%3D2023";
        },
        getMemberToken(data) {
            if (data != {} && data != undefined && data.member_token != null) {
                this.setPixMemberToken(data);
            } else {
                this.setLogoutData();
            }
        },
        blurSearchWithWindowSize(window_size, status) {
            if (window_size > 1024) {
                this.updateSearchActiveStatus(status)
            }
        },
        cleanText() {
            this.input_value = "";
            this.updateSearchAutocomplete([]);
        },
        hasUnread(val) {
            this.has_unread = val;
        },
        async getMemberLoginData(token) {
            if (token != "") {
                const API_url = "https://www.pixnet.net/mainpage/api/channel/getMember";
                const params = { member_token: token }
                try {
                    const data = await axios.get(API_url, {
                        params: params, responseType: "json"
                    });
                    this.setMemberData(data.data.data);
                } catch (e) {
                    console.log(e);
                }
            }
        },
        getCookie(cookie_name) {
            let name = cookie_name + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    let regex_str = /.*\|(.*)/;
                    let str = c.substring(name.length, c.length);
                    let group_str = regex_str.exec(str);
                    return JSON.parse(group_str[1]).data.safari_token;
                }
            }
            return "";
        },
        dynamicGetLoginToken() {
            const safari_token = this.getCookie("PIXCCSESSION");
            if (safari_token != undefined && safari_token != "" && safari_token != null) {
                this.setSafariPixMemberToken(safari_token);
                this.getMemberLoginData(this.member_token);
                this.checkLoginAndIs18up();
            }
            if (window.pix.member_token) {
                this.getMemberToken(window.pix);
                this.getMemberLoginData(this.member_token);
                this.checkLoginAndIs18up();
            }
        },
        paFunction() {
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::全站搜尋::inputbox::user_action', '', '']);
        },
        paClickSearchFunction() {
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'click::全站搜尋::inputbox::user_action', '', '']);
            this.$refs.search_bar.paFunction();
        },
        goCreateArticle() {
            if (this.is_login) {
                location.href = "https://panel.pixnet.cc/#/create-article";
            } else {
                location.href = "https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2Fpanel.pixnet.cc%2F%23%2Fcreate-article";
            }
        },
        async getAutoCompleteFromApi() {
            const vm = this;
            if (this.input_value != "") {
                const API_url = "https://api.pixnet.cc/tags/suggestions";
                const params = { q: this.input_value }
                const data = await axios.get(API_url, {
                    params: params, responseType: "json"
                }).catch((e) => {
                    console.log(e)
                });
                console.log('auto', data.data.data);
                const tmpResData = data.data.data;
                const tmpResLength = tmpResData.length;
                let tmpAutoCompleteArray = [];
                if(tmpResLength > 0) {
                    for(let i = 0;i < tmpResLength;i++) {
                        if(tmpResData[i].type == -1) {
                            tmpAutoCompleteArray.push(tmpResData[i]);
                        }
                    }
                }
                vm.isHasAutoComplete = tmpResLength == 0 ? false : true;
                vm.updateSearchAutocomplete(tmpAutoCompleteArray)
            } else {
                vm.updateSearchAutocomplete([])
            }
        },
        setSearchTextUrl(text) {
            let url = window.location.origin;
            let inputUrl = new URL(url);
            inputUrl.searchParams.set('q', text);
            window.open(inputUrl.href, '_self');
        },
        activeTabToIndex() {
            this.updateTabId(1);
            this.updateTabType('index');
            this.updateRouteName('index');
            this.setTabHighLighter(1)
            this.cleanBannerData();
        },
        setTabHighLighter(id) {
            const width = this.$refs.tab_ele.$refs.category[id - 1].clientWidth;
            const left = this.$refs.tab_ele.$refs.category[id - 1].getBoundingClientRect().left;
            this.updateTabLeft(left);
            this.updateTabWidth(width);
        },
        checkLoginAndIs18up() {
            if (this.$cookies.get(this.member_data.member_uniqid + 'is_adult') != undefined && this.$cookies.get(this.member_data.member_uniqid + 'is_adult') == 'true') {
                this.changeIs18UpStatus();
            }
        }
    },
    created() {
        this.debouncedHandler = debounce(e => {
            this.getAutoCompleteFromApi();
            if (this.input_value != "" && this.input_value != null && this.input_value != undefined) {
                this.updateInputValueActiveStatus(true);
            } else {
                this.updateInputValueActiveStatus(false);
            }
        }, 300);
    },
    mounted() {
        this.paFunction();
        this.dynamicGetLoginToken();
        this.getSearchHistory();
    },
    beforeUnmount() {
        this.debouncedHandler.cancel();
    },
}
